import React from "react"
import {List, Card} from 'antd';
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import CaseStudyArea from '../containers/global/case-study-area'
import CTAArea from '../containers/global/cta-area/section-one'
import { useStaticQuery, graphql } from "gatsby"
import FeatureBox from '../components/box-image/layout-three'
import { SectionWrap } from '../containers/index-services/features-area/features-area.stc';

import { 
    Tag
} from '../components/box-image/layout-three/box-image.style';
import {
    CategoryWrap
} from '../components/box-large-image/layout-two/box-large-image.style'




const TripPage = ({ pageContext, location }) => {

  const tripData = useStaticQuery(graphql `
        query {            
            trips: allAirtable(
                filter: {
                  table: { eq: "Trips" }
                }
              ) {
                edges {
                  node {
                    data {                      
                      Title
                      Slug
                      TripBlurb
                      TripCity
                      TripCTA
                      JourneyType
                      Photo{
                          url
                      }
                      Period
                      DestinationAirportCode
                    }
                  }
                }
            }
        }
    `)


    const trips = tripData.trips.edges;


   const info = {
       buttonText : "View Trip",
       url : "trip"
   }

   const getFilteredData = type => {
     return trips.filter(trip => (trip.node.data.JourneyType.includes(type)))
   }


 return (
  <Layout location={location}>
    <SEO title="Journeys"/>
    <Header/>    
    <SectionWrap>
            <h3 style={{textAlign:'left', margin:'20px 10px', fontSize:'30px', lineHeight:'30px'}}>
              Everyone loves traveling, not everyone loves planning. 
            </h3>
            <h5 style={{marginTop: '15px', margin:'20px 10px'}}>
              We have pre-planned journeys just waiting to give you the adventure of a lifetime. 
            </h5>
            <div>
              <span className="journey-type">
                Top Picks
              </span>
              <List
                  grid={{
                    gutter: 35,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  style={{marginTop:'40px', marginBottom:'40px', width:'100%'}}
                  dataSource={getFilteredData('Top Picks')}
                  renderItem={trip => (
                    <List.Item>
                      <Card className="feature-item" style={{backgroundImage:`url(${trip.node.data.Photo[0].url})`}}>
                          <div>
                          <CategoryWrap className="category-wrap">{trip.node.data.Period && <Tag background="#12B077" color="#fff" ml="5px">{trip.node.data.Period} Days</Tag>}</CategoryWrap>
                          <FeatureBox
                              title={trip.node.data.Title}
                              desc={trip.node.data.TripBlurb}
                              category={trip.node.data.TripCity}
                              tag={trip.node.data.Period + ' days'}
                              imageSrc={trip.node.data.Photo[0].url}
                              path={`/trip/${trip.node.data.Slug}`}
                              cta="Explore Now"
                          />
                          </div>
                      </Card>
                    </List.Item>
                  )}
              />
            </div>
            <div>
              <span className="journey-type" style={{background:'#FFA069'}}>
                Trending
              </span>
              <List
                  grid={{
                    gutter: 35,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  style={{marginTop:'40px', marginBottom:'40px'}}
                  dataSource={getFilteredData('Trending')}
                  renderItem={trip => (
                    <List.Item>
                      <Card className="feature-item" style={{backgroundImage:`url(${trip.node.data.Photo[0].url})`}}>
                          <div>
                          <CategoryWrap className="category-wrap">{trip.node.data.Period && <Tag background="#12B077" color="#fff" ml="5px">{trip.node.data.Period} Days</Tag>}</CategoryWrap>
                          <FeatureBox
                              title={trip.node.data.Title}
                              desc={trip.node.data.TripBlurb}
                              category={trip.node.data.TripCity}
                              tag={trip.node.data.Period + ' days'}
                              imageSrc={trip.node.data.Photo[0].url}
                              path={`/trip/${trip.node.data.Slug}`}
                              cta="Explore Now"
                          />
                          </div>
                      </Card>
                    </List.Item>
                  )}
              />
            </div> 
            <div>
              <span className="journey-type" style={{background:'#49CCCC'}}>
                Beach Vibes
              </span>
              <List
                  grid={{
                    gutter: 35,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  style={{marginTop:'40px', marginBottom:'40px'}}
                  dataSource={getFilteredData('Beach Vibes')}
                  renderItem={trip => (
                    <List.Item>
                      <Card className="feature-item" style={{backgroundImage:`url(${trip.node.data.Photo[0].url})`}}>
                          <div>
                          <CategoryWrap className="category-wrap">{trip.node.data.Period && <Tag background="#12B077" color="#fff" ml="5px">{trip.node.data.Period} Days</Tag>}</CategoryWrap>
                          <FeatureBox
                              title={trip.node.data.Title}
                              desc={trip.node.data.TripBlurb}
                              category={trip.node.data.TripCity}
                              tag={trip.node.data.Period + ' days'}
                              imageSrc={trip.node.data.Photo[0].url}
                              path={`/trip/${trip.node.data.Slug}`}
                              cta="Explore Now"
                          />
                          </div>
                      </Card>
                    </List.Item>
                  )}
              />
            </div>
            <div>
              <span className="journey-type" style={{background:'#81FF69'}}>
                Max Adventure
              </span>
              <List
                  grid={{
                    gutter: 35,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  style={{marginTop:'40px', marginBottom:'40px'}}
                  dataSource={getFilteredData('Max Adventure')}
                  renderItem={trip => (
                    <List.Item>
                      <Card className="feature-item" style={{backgroundImage:`url(${trip.node.data.Photo[0].url})`}}>
                          <div>
                          <CategoryWrap className="category-wrap">{trip.node.data.Period && <Tag background="#12B077" color="#fff" ml="5px">{trip.node.data.Period} Days</Tag>}</CategoryWrap>
                          <FeatureBox
                              title={trip.node.data.Title}
                              desc={trip.node.data.TripBlurb}
                              category={trip.node.data.TripCity}
                              tag={trip.node.data.Period + ' days'}
                              imageSrc={trip.node.data.Photo[0].url}
                              path={`/trip/${trip.node.data.Slug}`}
                              cta="Explore Now"
                          />
                          </div>
                      </Card>
                    </List.Item>
                  )}
              />
            </div>  
            <div>
              <span className="journey-type" style={{background:'#FFCE75'}}>
                Max Chill
              </span>
              <List
                  grid={{
                    gutter: 35,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  style={{marginTop:'40px', marginBottom:'40px'}}
                  dataSource={getFilteredData('Max Chill')}
                  renderItem={trip => (
                    <List.Item>
                      <Card className="feature-item" style={{backgroundImage:`url(${trip.node.data.Photo[0].url})`}}>
                          <div>
                          <CategoryWrap className="category-wrap">{trip.node.data.Period && <Tag background="#12B077" color="#fff" ml="5px">{trip.node.data.Period} Days</Tag>}</CategoryWrap>
                          <FeatureBox
                              title={trip.node.data.Title}
                              desc={trip.node.data.TripBlurb}
                              category={trip.node.data.TripCity}
                              tag={trip.node.data.Period + ' days'}
                              imageSrc={trip.node.data.Photo[0].url}
                              path={`/trip/${trip.node.data.Slug}`}
                              cta="Explore Now"
                          />
                          </div>
                      </Card>
                    </List.Item>
                  )}
              />
            </div>  
            <div>
              <span className="journey-type" style={{background:'#7269FF'}}>
                Family Fun
              </span>
              <List
                  grid={{
                    gutter: 35,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                  }}
                  style={{marginTop:'40px', marginBottom:'40px'}}
                  dataSource={getFilteredData('Family Fun')}
                  renderItem={trip => (
                    <List.Item>
                      <Card className="feature-item" style={{backgroundImage:`url(${trip.node.data.Photo[0].url})`}}>
                          <div>
                          <CategoryWrap className="category-wrap">{trip.node.data.Period && <Tag background="#12B077" color="#fff" ml="5px">{trip.node.data.Period} Days</Tag>}</CategoryWrap>
                          <FeatureBox
                              title={trip.node.data.Title}
                              desc={trip.node.data.TripBlurb}
                              category={trip.node.data.TripCity}
                              tag={trip.node.data.Period + ' days'}
                              imageSrc={trip.node.data.Photo[0].url}
                              path={`/trip/${trip.node.data.Slug}`}
                              cta="Explore Now"
                          />
                          </div>
                      </Card>
                    </List.Item>
                  )}
              />
            </div>  
            

      </SectionWrap>
    <CTAArea/>
    <Footer/>
  </Layout>
)}
 
export default TripPage
 